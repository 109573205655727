export default {
  componentSingleton: 'tippy-singleton',
  defaultProps: {
    delay: 400,
    theme: 'dark',
    touch: ['hold', 500],
    trigger: 'mouseenter focus',
    onCreate(instance) {
      //console.log('VueTippy defaultProps onCreate', JSON.stringify(instance.state), instance, instance.reference.dataset.showtippyonclick)
      const isShowOnClick = instance.props.disarea_showOnClick || instance.reference.dataset.showtippyonclick
      if (isShowOnClick) {
        instance.setProps({
          disarea_showOnClick: true
        })

        if (!instance.props.trigger.includes('click')) {
          instance.setProps({
            trigger: instance.props.trigger + ' click'
          })
        }
      }
    },
    onTrigger(instance, event) {
      //console.log('VueTippy defaultProps onTrigger', instance, event, JSON.stringify(instance.state))
      if (instance.props.disarea_showOnClick && event.type === 'click') {
        // instance.props.delay = 1
        instance.setProps({
          delay: 1
        })

        if (instance.state.isShown) {
          instance.__disareaNeedCancelShow = true
          instance.hide()
        } else {
          event.target.addEventListener('mouseleave', () => {
            if (typeof instance?.hide === 'function') {
              instance.hide()
            }
          }, {once: true})
        }
      }
    },
    onShow(instance) {
      //console.log('VueTippy defaultProps onShow', instance)
      if (instance.__disareaNeedCancelShow) {
        delete instance.__disareaNeedCancelShow
        return false
      }
      return true
    },
    // onShown(instance) {
    //   //console.log('VueTippy defaultProps onShown', instance)
    //   // if (instance.props.disarea_showOnClick) {
    //   //   instance.props.delay = 400
    //   // }
    // },
    onHidden(instance) {
      //console.log('VueTippy defaultProps onHide', instance)
      if (instance.props.disarea_showOnClick) {
        //instance.props.delay = 400
        instance.setProps({
          delay: 400
        })
      }
    },
  }
}
